import { message } from "antd";
import axios from "axios";
// import SignOut from "../feature-module/pages/login/SignOut";

// Create an instance of axios with default settings
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure that API base URL is correctly set in your .env file
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config) => {
    // Retrieve the JWT token from localStorage or any other storage mechanism
    const token = localStorage.getItem("jwt_token"); // Make sure to store it securely

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.log("auth token mismatch");

    return Promise.reject(error);
  }
);

export const HeaderGroup = {
  none: {},
  multipart: { "Content-Type": "multipart/form-data" },
};

export const HandleError = (error) => {
  console.log("error");
  message.error(error?.response?.data?.message ?? "Something Went Wrong");
  if (error?.response?.status === 401) {
    localStorage.clear();
    window.location.replace("/signin");
  }
};

// GET Request
export const GetRequest = async (api, params = {}) => {
  return apiClient
    .get(api, { params })
    .then((response) =>
      response && response?.data ? response.data : HandleError()
    )
    .catch((error) => {
      HandleError(error); // Only throw error if you need to log or handle it differently
    });
};

// POST Request
export const PostRequest = async (api, body, header = HeaderGroup.none) => {
  return apiClient
    .post(api, body, { headers: header })
    .then((response) =>
      response && response?.data ? response.data : HandleError()
    )
    .catch((error) => {
      HandleError(error); // Only throw error if you need to log or handle it differently
    });
};

// PUT Request (usually used to update resources, replace the whole resource)
export const PutRequest = async (api, body) => {
  return apiClient
    .put(api, body)
    .then((response) =>
      response && response?.data ? response.data : HandleError()
    )
    .catch((error) => {
      HandleError(error); // Only throw error if you need to log or handle it differently
    });
};

// PATCH Request (usually used to partially update a resource)
export const PatchRequest = async (api, body) => {
  return apiClient
    .patch(api, body)
    .then((response) =>
      response && response?.data ? response.data : HandleError()
    )
    .catch((error) => {
      HandleError(error); // Only throw error if you need to log or handle it differently
    });
};

// DELETE Request
export const DeleteRequest = async (api) => {
  return apiClient
    .delete(api)
    .then((response) =>
      response && response?.data ? response.data : HandleError()
    )
    .catch((error) => {
      HandleError(error); // Only throw error if you need to log or handle it differently
    });
};
