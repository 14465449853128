import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useForm } from "react-hook-form";
import { PostRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { setrolesandpermission_data } from "../../../core/redux/action";

const Signin = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const googleLogin = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    // setValue,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  const route = all_routes;
  const [token, setToken] = useState(localStorage.getItem("jwt_token"));

  useEffect(() => {
    // This will track any changes in localStorage for the jwt_token
    const handleStorageChange = () => {
      setToken(localStorage.getItem("jwt_token"));
    };

    // Add event listener for storage changes
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const onSubmit = async (data) => {
    const res = await PostRequest(`${ApiUrl.user}/login`, data);
    if (res) {
      message.success("Sign In Successfull");
      dispatch(setrolesandpermission_data(res));
      localStorage.setItem("jwt_token", res.access_token);
      setToken(res.access_token);
      reset();
      navigate(route.productlist);
      console.log(token);
    }
  };
  return (
    <div className="main-wrapper">
      <div className="account-content">
        <div className="login-wrapper bg-img">
          <div className="login-content">
            <form action="index" onSubmit={handleSubmit(onSubmit)}>
              <div className="login-userset">
                <div className="login-logo logo-normal">
                  <img src="https://thehorizonhub.com/assets/MyImages/HBLogo.svg" />
                  {/* <ImageWithBasePath src="assets/img/logo.png" alt="img" /> */}
                </div>
                {/* <Link to={route.dashboard} className="login-logo logo-white">
                  <ImageWithBasePath src="assets/img/logo-white.png" alt />
                </Link> */}
                <div className="login-userheading">
                  <h3>Sign In</h3>
                  <h4>
                    Access the Pharmatrace Tatmeem panel using your email and
                    passcode.
                  </h4>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Email Address</label>
                  <div className="form-addons">
                    <input
                      type="text"
                      className="form- control"
                      {...register("email", {
                        required: "email is required",
                      })}
                    />
                    <ImageWithBasePath
                      src="assets/img/icons/mail.svg"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="form-login mb-3">
                  <label className="form-label">Password</label>
                  <div className="pass-group">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      className="pass-input form-control"
                      {...register("password", {
                        required: "name is required",
                      })}
                    />
                    <span
                      className={`fas toggle-password ${
                        isPasswordVisible ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={togglePasswordVisibility}
                    ></span>
                  </div>
                </div>
                {/* <div className="form-login authentication-check">
                  <div className="row">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <div className="custom-control custom-checkbox">
                        <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                          <input type="checkbox" className="form-control" />
                          <span className="checkmarks" />
                          Remember me
                        </label>
                      </div>
                      <div className="text-end">
                        <Link className="forgot-link" to={route.forgotPassword}>
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div type="submit" className="form-login">
                  <button type="submit" className="btn btn-login">
                    {isSubmitting ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
                <div className="signinform">
                  <h4>
                    New on our platform?
                    <Link to={route.register} className="hover-a">
                      {" "}
                      Create an account
                    </Link>
                  </h4>
                </div>
                {googleLogin && (
                  <div className="form-setlogin or-text">
                    <h4>OR</h4>
                  </div>
                )}
                <div className="form-sociallink">
                  <ul className="d-flex justify-content-center">
                    {/* <li>
                      <Link to="#" className="facebook-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/facebook-logo.svg"
                          alt="Facebook"
                        />
                      </Link>
                    </li> */}
                    {googleLogin && (
                      <li>
                        <Link to="#">
                          <ImageWithBasePath
                            src="assets/img/icons/google.png"
                            alt="Google"
                          />
                        </Link>
                      </li>
                    )}
                    {/* <li>
                      <Link to="#" className="apple-logo">
                        <ImageWithBasePath
                          src="assets/img/icons/apple-logo.svg"
                          alt="Apple"
                        />
                      </Link>
                    </li> */}
                  </ul>
                  <div className="my-4 d-flex justify-content-center align-items-center copyright-text">
                    <p>Copyright © 2024 HorizonHub. All rights reserved</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
