import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, publicRoutes } from "./router.link";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { all_routes } from "./all_routes";
// import ThemeSettings from "../InitialPage/themeSettings";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  const loginPart = useSelector((state) => state.rolesandpermission_data);
  // const token = localStorage.getItem("jwt_token");
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      {/* <Loader /> */}
      <Header />
      <Sidebar />
      <Outlet />
      {/* <ThemeSettings /> */}
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      {/* <Loader /> */}
      {/* <ThemeSettings /> */}
    </div>
  );
  console.log(loginPart, "login");

  return (
    <div>
      <Routes>
        {/* <Route path={"/"} element={<HeaderLayout />}>
          {token ? (
            publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))
          ) : (
            <Navigate to={all_routes.signin} />
          )}
        </Route> */}
        {loginPart.access_token ? (
          <Route path="/" element={<HeaderLayout />}>
            {publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
        ) : (
          // Redirect to Sign In if not authenticated
          <Route path="*" element={<Navigate to={all_routes.signin} />} />
        )}

        <Route path={"/"} element={<Authpages />}>
          <Route path="/" element={<Navigate to={all_routes.signin} />} />

          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
      </Routes>
    </div>
  );
};
export default AllRoutes;
