import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Eye } from "react-feather";
import Table from "../../pagination/datatable";
import Breadcrumbs from "../../breadcrumbs";
import CustomerModal from "./customerModal";
import { GetRequest, PostRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
import { message } from "antd";

const SPallet = () => {
  const [data, setData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchData = async () => {
    const res = await GetRequest(`${ApiUrl.package}/aggregate`);
    if (res) {
      setData(res);
    }
  };

  useEffect(() => fetchData, []);

  const Tatmeem = async () => {
    const res = await PostRequest(ApiUrl.tatmeem, {
      type: "PACKING",
      parent: selectedRowKeys,
    });
    if (res) {
      message.success("Added for Tatmeem upload");
      setSelectedRowKeys([]);
    }
  };
  const columns = [
    {
      title: "SSCC",
      dataIndex: "sscc",
      sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (d) => <p>{new Date(d).toLocaleDateString()}</p>,
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Total Items",
      dataIndex: "aggregationHistories",
      render: (d) => <p>{d.length}</p>,
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: () => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            <Link className="me-2 p-2" to="#">
              <Eye className="feather-view" />
            </Link>

            {/* <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <Edit className="feather-edit" />
            </Link>

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={showDeleteConfirmationAlert}
            >
              <Trash2 className="feather-trash-2" />
            </Link> */}
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Aggregate"
          subtitle="Aggregate your products"
          // addButton="Add Product"
          refreshButtonAction={fetchData}
          tatmeemButtonAction={selectedRowKeys.length > 0 && Tatmeem}
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <CustomerModal />
    </div>
  );
};

export default SPallet;
