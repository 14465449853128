import React from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const BarcodePrinter = ({ data }) => {
  return (
    <div>
      {/* Print Barcode */}
      <div className="modal fade" id="prints-barcode">
        <div className="modal-dialog modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Searialisation Barcode</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  {/* <div className="d-flex justify-content-end">
                    <Link className="btn btn-cancel close-btn">
                      <span>
                        <i className="fas fa-print me-2" />
                      </span>
                      Print Barcode
                    </Link>
                  </div> */}
                  {/* <div className="barcode-scan-header">
                    <h5>Barcodes</h5>
                  </div> */}
                  <div className="row">
                    {data?.map((item, i) => (
                      <div key={i} className="col-sm-4">
                        <div className="barcode-scanner-link text-center">
                          <p>{item.uniqueKey}</p>
                          <div className="barscaner-img">
                            <img
                              src={`${process.env.REACT_APP_API_BASE_URL}/uploads/IMG/${item.uniqueKey}_GS1_DataMatrix.png`}
                              alt="Barcode"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Print Barcode */}
    </div>
  );
};

BarcodePrinter.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default BarcodePrinter;
