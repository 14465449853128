import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trash2 } from "react-feather";
import Table from "../../pagination/datatable";
import Breadcrumbs from "../../breadcrumbs";
import CustomerModal from "./customerModal";
import QRCodeScannerComponent from "./BarcodeScanner";
import { showDeleteConfirmationAlert } from "../../../functions/SwalAlerts";
import {
  DeleteRequest,
  GetRequest,
  PostRequest,
} from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
import { message } from "antd";

const SPackage = () => {
  const [data, setData] = useState(null);
  const [ShowScanner, setShowScanner] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onBarcodeScan = async (d) => {
    alert(d);
    setShowScanner(false);
    await PostRequest(`${ApiUrl.package}/qrcode`, { code: d.text });
    fetchData();
  };

  const fetchData = async () => {
    const res = await GetRequest(ApiUrl.package);
    if (res) {
      setData(res);
    }
  };
  const AggregateData = async () => {
    const res = await PostRequest(`${ApiUrl.package}/aggregate`, {
      id: selectedRowKeys,
    });
    console.log(res);
    if (res) {
      message.success("Added new Aggregation");
      setSelectedRowKeys([]);
      fetchData();
    }
  };

  const DeleteItem = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.package}/${id}`);
    if (res) {
      fetchData();
    }
  };

  useEffect(() => fetchData, []);

  const columns = [
    {
      title: "GTIN",
      dataIndex: "gtin",
      sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Expiry",
      dataIndex: "expiry",
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            {/* <Link className="me-2 p-2" to="#">
              <Eye className="feather-view" />
            </Link> */}

            {/* <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <Edit className="feather-edit" />
            </Link> */}

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showDeleteConfirmationAlert(record.id, DeleteItem)}
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        {ShowScanner && (
          <QRCodeScannerComponent
            onScanned={(d) => {
              onBarcodeScan(d);
            }}
          />
        )}

        <Breadcrumbs
          maintitle="Pack"
          subtitle="Manage Your Package"
          addButton="Scan QR"
          importbutton={`${selectedRowKeys?.length > 0 ? "Aggregate" : ""}`}
          addButtonAction={() => setShowScanner(true)}
          importButtonAction={selectedRowKeys.length > 0 && AggregateData}
          refreshButtonAction={fetchData}
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <CustomerModal />
    </div>
  );
};

export default SPackage;
