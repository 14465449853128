/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Table } from "antd";

const Datatable = ({
  props,
  columns,
  dataSource,
  // onSelectionChange,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    if (setSelectedRowKeys) {
      setSelectedRowKeys(newSelectedRowKeys);
    }
    // onSelectionChange(newSelectedRowKeys);
  };

  const rowSelection =
    selectedRowKeys && setSelectedRowKeys
      ? {
          selectedRowKeys,
          onChange: onSelectChange,
        }
      : null;
  return (
    <Table
      key={props}
      className="table datanew dataTable no-footer"
      rowSelection={rowSelection}
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.id}
      loading={!dataSource}
    />
  );
};

export default Datatable;
