import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trash2, Eye, Download } from "react-feather";
import Table from "../../pagination/datatable";
import Breadcrumbs from "../../breadcrumbs";
import BarcodePrinter from "../inventory/barcodePrinter";
import BatchModal from "./batchModal";
import {
  DeleteRequest,
  GetRequest,
  PostRequest,
} from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
import { showDeleteConfirmationAlert } from "../../../functions/SwalAlerts";
import { message } from "antd";

const SBatch = () => {
  const [data, setData] = useState(null);
  const [Products, setProducts] = useState([]);
  const [barSelect, setBarSelect] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const handleScan = (data) => {
  //   // alert(`Scanned Data: ${data}`);
  //   console.log(data);
  //   // Handle scanned GS1 DataMatrix data
  // };

  const fetchdata = async () => {
    const res = await GetRequest(ApiUrl.batch);
    const proRes = await GetRequest(ApiUrl.product);

    console.log(res, "res");
    if (res) {
      setData(res);
    }
    if (proRes) {
      setProducts(proRes);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const Tatmeem = async () => {
    const res = await PostRequest(ApiUrl.tatmeem, {
      type: "COMMISSIONING",
      parent: selectedRowKeys,
    });
    if (res) {
      message.success("Added For tatmeem upload");
      setSelectedRowKeys([]);
    }
  };

  const PrintBarcode = async (id) => {
    console.log(id);
    const res = await GetRequest(`${ApiUrl.batch}/${id}`);
    if (res) {
      const fileUrl = `${process.env.REACT_APP_API_BASE_URL}/uploads/PDF/${res.filePath}`;
      window.open(fileUrl, "_blank");
    }
  };

  const DeleteBatch = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.batch}/${id}`);
    if (res) {
      fetchdata();
    }
  };

  const columns = [
    {
      title: "Batch",
      dataIndex: "formattedId",
      // render: (data) => `B${data.toString().padStart(5, "0")}`,
      sorter: (a, b) => a.CustomerName.length - b.CustomerName.length,
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (dat) => <p>{dat?.name}</p>,
      sorter: (a, b) => a.Code.length - b.Code.length,
    },
    {
      title: "Expiry Date",
      dataIndex: "expiry",
      render: (d) => new Date(d).toLocaleString(),
      sorter: (a, b) => a.Customer.length - b.Customer.length,
    },

    {
      title: "Manufacture Date",
      dataIndex: "manufacture",
      render: (d) => new Date(d).toLocaleString(),
      sorter: (a, b) => a.Email.length - b.Email.length,
    },

    {
      title: "Batch Total Items",
      dataIndex: "totalItems",
      sorter: (a, b) => a.Phone.length - b.Phone.length,
    },

    {
      title: "Permit (Shipment)",
      dataIndex: "permit",
      sorter: (a, b) => a.Country.length - b.Country.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            {record?.batchItems?.length > 0 && (
              <Link
                className="me-2 p-2"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#prints-barcode"
                onClick={() => setBarSelect(record?.batchItems)}
              >
                <Eye className="feather-view" />
              </Link>
            )}
            {record?.batchItems?.length > 0 && (
              <Link
                className="me-2 p-2"
                onClick={() => PrintBarcode(record?.id)}
              >
                <Download className="feather-view" />
              </Link>
            )}

            {/* <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <Edit className="feather-edit" />
            </Link>*/}

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() =>
                showDeleteConfirmationAlert(record.id, DeleteBatch)
              }
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Batch List"
          subtitle="Manage Your Batch"
          addButton="Add Batch"
          tatmeemButtonAction={selectedRowKeys.length > 0 && Tatmeem}
          refreshButtonAction={fetchdata}
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <BatchModal
        Products={Products.map((it) => ({ value: it.id, label: it.name }))}
        OnFinish={fetchdata}
      />
      <BarcodePrinter data={barSelect} />
    </div>
  );
};

export default SBatch;
