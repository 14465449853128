import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Trash2 } from "react-feather";
import Table from "../../pagination/datatable";
import Breadcrumbs from "../../breadcrumbs";
import { showDeleteConfirmationAlert } from "../../../functions/SwalAlerts";
import { DeleteRequest, GetRequest } from "../../../Api/ApiFunctions";
import { ApiUrl } from "../../../Api/EndPoints";
import ProductModal from "./productModal";

const SProducts = () => {
  const [data, setData] = useState(null);

  const fetchdata = async () => {
    const res = await GetRequest(ApiUrl.product);
    if (res) {
      setData(res);
    }
  };

  const deleteProduct = async (id) => {
    const res = await DeleteRequest(`${ApiUrl.product}/${id}`);
    if (res) {
      fetchdata();
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const columns = [
    {
      title: "Product Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "GTIN",
      dataIndex: "gtin",
      sorter: (a, b) => a.gtin.length - b.gtin.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (text, record) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            <div className="input-block add-lists"></div>

            {/* <Link className="me-2 p-2" to="#">
              <Eye className="feather-view" />
            </Link> */}

            {/* <Link
              className="me-2 p-2"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit-units"
            >
              <Edit className="feather-edit" />
            </Link> */}

            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() =>
                showDeleteConfirmationAlert(record.id, deleteProduct)
              }
            >
              <Trash2 className="feather-trash-2" />
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <Breadcrumbs
          maintitle="Products"
          subtitle="Manage Your Products"
          addButton="Add Product"
          refreshButtonAction={fetchdata}
        />

        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <div className="search-input">
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control form-control-sm formsearch"
                  />
                  <Link to className="btn btn-searchset">
                    <i data-feather="search" className="feather-search" />
                  </Link>
                </div>
              </div>
              {/* <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div>
              <div className="form-sort stylewidth">
                <Sliders className="info-img" />

                <Select
                  classNamePrefix="react-select"
                  className="img-select"
                  options={options}
                  placeholder="Sort by Date"
                />
              </div> */}
            </div>

            <div className="table-responsive">
              <Table
                className="table datanew"
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
                // pagination={true}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <ProductModal OnFinish={fetchdata} />
    </div>
  );
};

export default SProducts;
